exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dakujeme-sk-js": () => import("./../../../src/pages/dakujeme.sk.js" /* webpackChunkName: "component---src-pages-dakujeme-sk-js" */),
  "component---src-pages-for-sellers-en-js": () => import("./../../../src/pages/for-sellers.en.js" /* webpackChunkName: "component---src-pages-for-sellers-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-sk-js": () => import("./../../../src/pages/index.sk.js" /* webpackChunkName: "component---src-pages-index-sk-js" */),
  "component---src-pages-informacie-sk-js": () => import("./../../../src/pages/informacie.sk.js" /* webpackChunkName: "component---src-pages-informacie-sk-js" */),
  "component---src-pages-information-en-js": () => import("./../../../src/pages/information.en.js" /* webpackChunkName: "component---src-pages-information-en-js" */),
  "component---src-pages-pre-predajcov-sk-js": () => import("./../../../src/pages/pre-predajcov.sk.js" /* webpackChunkName: "component---src-pages-pre-predajcov-sk-js" */),
  "component---src-pages-prevadzky-sk-js": () => import("./../../../src/pages/prevadzky.sk.js" /* webpackChunkName: "component---src-pages-prevadzky-sk-js" */),
  "component---src-pages-stores-en-js": () => import("./../../../src/pages/stores.en.js" /* webpackChunkName: "component---src-pages-stores-en-js" */),
  "component---src-pages-thank-you-en-js": () => import("./../../../src/pages/thank-you.en.js" /* webpackChunkName: "component---src-pages-thank-you-en-js" */),
  "component---src-templates-events-archive-js": () => import("./../../../src/templates/events-archive.js" /* webpackChunkName: "component---src-templates-events-archive-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news-archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-store-archive-fm-js": () => import("./../../../src/templates/store-archive-fm.js" /* webpackChunkName: "component---src-templates-store-archive-fm-js" */),
  "component---src-templates-store-archive-js": () => import("./../../../src/templates/store-archive.js" /* webpackChunkName: "component---src-templates-store-archive-js" */)
}

